import { createAsyncThunk } from "@reduxjs/toolkit";

export const removeOrderLock = createAsyncThunk("order/removeOrderLock", async (args, thunkAPI) => {
  const {
    BillingPage: { orderId, token }
  } = thunkAPI.getState();

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: `
        mutation RemoveOrderLock($orderId: ID!, $token: String!) {
          removeOrderLock(orderId: $orderId, token: $token)
        }
      `,
      variables: {
        orderId,
        token
      }
    })
  });

  const { data, errors } = await response.json();

  if (errors) {
    throw new Error(errors.map(item => item.message).join("\n"));
  }

  const { removeOrderLock } = data;
  if (!removeOrderLock) {
    throw new Error("Can not remove order lock!");
  }
  return true;
});
