// extracted by mini-css-extract-plugin
export var thankPage = "ThankyouPage-module--thankPage--2ZG4q";
export var content = "ThankyouPage-module--content--3Vr-M";
export var titleContainer = "ThankyouPage-module--titleContainer--2zJ80";
export var title = "ThankyouPage-module--title--xTBsz";
export var subtitle = "ThankyouPage-module--subtitle--1ckD-";
export var subtitleBold = "ThankyouPage-module--subtitleBold--3uBQ7";
export var errorCard = "ThankyouPage-module--errorCard--Ze75n";
export var sentEmailCard = "ThankyouPage-module--sentEmailCard--2yccO";
export var icon = "ThankyouPage-module--icon--3m9i6";
export var textColumn = "ThankyouPage-module--textColumn--37lvo";
export var cardTitle = "ThankyouPage-module--cardTitle--1zmVx";
export var cardTitleSent = "ThankyouPage-module--cardTitleSent--1oeay";
export var cardText = "ThankyouPage-module--cardText--29quo";
export var labelInput = "ThankyouPage-module--labelInput--22gxW";
export var inputContainer = "ThankyouPage-module--inputContainer--1Xrmq";
export var updateEmailButton = "ThankyouPage-module--updateEmailButton--Kk4P3";
export var benefits = "ThankyouPage-module--benefits--3Jnnt";
export var checkIcon = "ThankyouPage-module--checkIcon--2qaal";
export var benefitsTitle = "ThankyouPage-module--benefitsTitle--nL-IV";
export var benefitItem = "ThankyouPage-module--benefitItem--1-iYJ";
export var benefitItemBlue = "ThankyouPage-module--benefitItemBlue--2SLHr";
export var benefitsColumn = "ThankyouPage-module--benefitsColumn--2ZG03";
export var textTitle = "ThankyouPage-module--textTitle--1RttH";
export var textTitleRed = "ThankyouPage-module--textTitleRed--2YIZE";
export var linkItem = "ThankyouPage-module--linkItem--3a5t7";
export var linkItemRow = "ThankyouPage-module--linkItemRow--rrKds";
export var linkIcon = "ThankyouPage-module--linkIcon--pKZ-q";
export var linkText = "ThankyouPage-module--linkText--11EsJ";
export var linkTextBlack = "ThankyouPage-module--linkTextBlack--2N4LE";
export var benefitItemText = "ThankyouPage-module--benefitItemText--3LC-y";
export var benefitItemText2 = "ThankyouPage-module--benefitItemText2--cVNCk";
export var benefitItemTextBlue = "ThankyouPage-module--benefitItemTextBlue--3XNQQ";
export var zerobounce = "ThankyouPage-module--zerobounce--3xIyn";
export var errorMessage = "ThankyouPage-module--errorMessage--agYjD";
export var footerContainer = "ThankyouPage-module--footerContainer--2RPU3";