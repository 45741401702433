import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { graphql } from "gatsby";
import { trackingGA } from "@merchx-v2/helpers";
import { useSiteMetadata } from "hooks/use-site-metadata";
import { navigate } from "utils/navigate";
import {
  Button,
  Footer,
  Header,
  Input,
  Notification,
  ShopCollection,
  SEO,
} from "components";
import checkBenefitsIcon from "assets/checkBenefits.svg";
import errorIcon from "assets/error.svg";
import checkBlueIcon from "assets/checkBlueCircle.svg";
import chatIcon from "assets/chatCircle.svg";
import emailIcon from "assets/email.svg";
import headphonesIcon from "assets/headphones.svg";
import {
  actionCreators as actionHomeCreators,
  selectCartId,
  selectHomeState,
  selectMemberEconomyDetails,
  selectSubscriptionProduct,
  selectMenu,
} from "state/HomePage/reducer";
import { selectBillingState } from "state/BillingPage/reducer";
import { selectShippingState } from "state/ShippingPage/reducer";
import * as shippingActions from "state/ShippingPage/actions";
import {
  selectors as loginSelectors,
  actionCreators as loginActionCreators,
} from "state/LoginPage/reducer";
import * as actions from "state/ThankyouPage/actions";
import {
  customerAccountRegister,
  customerAccountCreateSubscription,
} from "state/LoginPage/actions";
import * as styles from "./ThankyouPage.module.scss";

const ThankyouPage = ({
  homeState,
  billingState,
  shippingState,
  loginState,

  cleanError,
  customerAccountRegister,
  customerAccountCreateSubscription,
  clearCart,
  removeOrderLock,
  updateCartCustomerEmail,
  subscriptionProduct,
  data,
  menu,
  resetMenuFilters,
  setMenuCollection,
}) => {
  const { shared, landing } = useSiteMetadata();

  const supportEmail = shared.SUPPORT_EMAIL;
  const chatLink = shared.CHAT_LINK;

  let pageTag =
    data.markdownRemark?.frontmatter?.GOOGLE_SALE_CONVERTION_SNIPPET;
  if (pageTag) {
    pageTag = pageTag.replace(/\[%\{script\}%\]/g, "");
    pageTag = pageTag.replace(/\[%\{\/script\}%\]/g, "");
    pageTag = pageTag.replace(/\[%\{script\s/g, "<script ");
  }

  const [isUpgradeMembershipClicked, setIsUpgradeMembershipClicked] = useState(
    false
  );
  const [isAccountRegistered, setIsAccountRegistered] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isShowErrors, setIsShowErrors] = useState(false);
  const [notification, showNotification] = useState();
  const [email, setEmail] = useState();

  const isNonMember = loginState.membershipInfo.status === "ANONYMOUS";
  const isFreeMember = loginState.membershipInfo.status === "FREE";
  const isActiveMember = ["ACTIVE", "TRIAL", "EXPIRING"].includes(
    loginState.membershipInfo.status
  );

  useEffect(() => {
    if (removeOrderLock && billingState.token && billingState.orderId) {
      removeOrderLock();
    }
  }, [billingState.token, billingState.orderId, removeOrderLock]);

  useEffect(() => {
    trackingGA();
    const script = document.createElement("script");

    script.text = pageTag;

    if (pageTag) {
      document.head.appendChild(script);
    }

    return () => {
      if (pageTag) {
        document.head.removeChild(script);
      }
      cleanError();
      clearCart();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      shared.FACEBOOK_PIXEL_ID &&
      homeState &&
      homeState.cart &&
      homeState.cart.orderId
    ) {
      import("react-facebook-pixel")
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.init(shared.FACEBOOK_PIXEL_ID);
          ReactPixel.fbq(
            "track",
            "Purchase",
            { value: parseFloat(homeState.cart.total), currency: "USD" },
            { eventID: homeState.cart.orderId }
          );
        });
    }
  }, [homeState]);

  useEffect(() => {
    showNotification(loginState.UIState.error);
  }, [loginState.UIState.error]);

  let message;
  if (
    shippingState.UIState.error &&
    shippingState.UIState.error === "Email address already used!"
  ) {
    message = "This email is taken. Please use a different one.";
  }

  if (!homeState.cart?.items?.length) {
    return navigate("/");
  }

  const { email: firstName } = loginState;

  const handleChange = (email) => {
    setIsShowErrors(false);
    setEmail(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsShowErrors(true);
    await updateCartCustomerEmail({ email });
  };

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  return (
    <div className={styles.thankPage}>
      <SEO pathname="/thankyou" />

      <div className={styles.content}>
        <Header
          menu={menu}
          onCollectionClicked={handleCollectionClicked}
          onResetCollection={handleReset}
        />
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            Thanks, {firstName || shippingState.firstName || "my friend"}!
          </div>
          <p className={styles.subtitle}>
            Your order number is:{" "}
            <span className={styles.subtitleBold}>
              {homeState.cart.orderId || "00001"}
            </span>
          </p>
          <p className={styles.subtitle}>
            Your order and VIP membership trial is confirmed.
          </p>
        </div>
        {!shippingState.emailValid ? (
          <>
            <div className={styles.errorCard}>
              <img className={styles.icon} src={errorIcon} alt="X" />
              <div className={styles.textColumn}>
                <p className={styles.cardTitle}>INVALID Email Address</p>
                <p className={styles.cardText}>
                  The email you used is invalid, please provide a different one.
                </p>
              </div>
            </div>

            <div className={styles.zerobounce}>
              <div className={styles.inputContainer}>
                <p className={styles.labelInput}>Enter valid email address:</p>
                {message && <p className={styles.errorMessage}>{message}</p>}
                <Input
                  placeholder="Email Address"
                  name="email"
                  autoComplete="home email"
                  onChange={handleChange}
                  isValid={shippingState.validation.email.isValid}
                  isShowErrors={isShowErrors}
                  errorMessage={shippingState.validation.email.error}
                />
              </div>
              <div className={styles.updateEmailButton}>
                <Button
                  customAction={handleSubmit}
                  value="Update Email"
                  loading={shippingState.UIState.isLoading}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.sentEmailCard}>
            <img className={styles.icon} src={checkBlueIcon} alt="✔" />
            <div className={styles.textColumn}>
              <p className={styles.cardTitleSent}>VIP Login Sent</p>
              <p className={styles.cardText}>
                Please check your email for VIP login details.
              </p>
            </div>
          </div>
        )}
        <section className={styles.benefits}>
          <p className={styles.benefitsTitle}>
            Take a look at your VIP Benefits
          </p>
          <div className={styles.benefitItemBlue}>
            <img className={styles.checkIcon} src={checkBenefitsIcon} alt="✔" />
            <div className={styles.benefitsColumn}>
              <p className={styles.textTitle}>Concierge support 24/7</p>
              <div className={styles.linkItem}>
                <img className={styles.linkIcon} src={chatIcon} alt="Chat" />
                <p className={styles.linkTextBlack}>Text 855-701-0832</p>
              </div>
              <div className={styles.linkItem}>
                <img className={styles.linkIcon} src={emailIcon} alt="Email" />
                <a href={`mailto:${supportEmail}`} className={styles.linkText}>
                  Email us here
                </a>
              </div>
              <div className={styles.linkItem}>
                <img
                  className={styles.linkIcon}
                  src={headphonesIcon}
                  alt="Chat"
                />
                <a
                  href={chatLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={styles.linkText}
                >
                  Chat here
                </a>
              </div>
            </div>
          </div>
          <div className={styles.benefitItem}>
            <img className={styles.checkIcon} src={checkBenefitsIcon} alt="✔" />
            <div className={styles.benefitsColumn}>
              <p className={styles.textTitle}>$0 profit pricing</p>
              <div className={styles.linkItemRow}>
                <p className={styles.benefitItemText}>
                  Get all items at wholesale prices.
                </p>
                <p className={styles.benefitItemText2}>
                  $0 shirts // $10 hoodies // and more
                </p>
              </div>
              <div className={styles.linkItem}>
                <a
                  href="/login"
                  rel="noopener noreferrer"
                  target="_blank"
                  className={styles.benefitItemTextBlue}
                >
                  Shop Discounts Now
                </a>
              </div>
            </div>
          </div>

          <div className={styles.benefitItemBlue}>
            <img className={styles.checkIcon} src={checkBenefitsIcon} alt="✔" />
            <div className={styles.benefitsColumn}>
              <p className={styles.textTitle}>Free custom design</p>
              <div className={styles.linkItemRow}>
                <p className={styles.benefitItemText}>
                  Talk to a designer, and tell them your idea, and we bring it
                  to life.
                </p>
              </div>
              <div className={styles.linkItem}>
                <a
                  href={chatLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={styles.benefitItemTextBlue}
                >
                  Chat With Designer
                </a>
              </div>
            </div>
          </div>

          <div className={styles.benefitItem}>
            <img className={styles.checkIcon} src={checkBenefitsIcon} alt="✔" />
            <div className={styles.benefitsColumn}>
              <p className={styles.textTitle}>
                Collections -{" "}
                <span className={styles.textTitleRed}>Coming Soon</span>
              </p>
            </div>
          </div>

          <div className={styles.benefitItemBlue}>
            <img className={styles.checkIcon} src={checkBenefitsIcon} alt="✔" />
            <div className={styles.benefitsColumn}>
              <p className={styles.textTitle}>
                Free entry to monthly giveaways
              </p>
            </div>
          </div>

          <div className={styles.benefitItem}>
            <img className={styles.checkIcon} src={checkBenefitsIcon} alt="✔" />
            <div className={styles.benefitsColumn}>
              <p className={styles.textTitle}>Bulk order deals</p>
            </div>
          </div>
        </section>
        <ShopCollection />
      </div>

      <div className={styles.footerContainer}>
        <Footer />
        <Notification message={notification} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartId: selectCartId(state),
  homeState: selectHomeState(state),
  subscriptionProduct: selectSubscriptionProduct(state),
  billingState: selectBillingState(state),
  shippingState: selectShippingState(state),
  loginState: loginSelectors.selectLoginState(state),
  memberEconomy: selectMemberEconomyDetails(state),
  menu: selectMenu(state),
});

const mapDispatchToProps = {
  cleanError: loginActionCreators.cleanError,
  clearCart: actionHomeCreators.clearCart,
  removeOrderLock: actions.removeOrderLock,
  customerAccountCreateSubscription,
  customerAccountRegister,
  updateCartCustomerEmail: shippingActions.updateCartCustomerEmail,
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankyouPage);

export const query = graphql`
  query ThankYouPage {
    markdownRemark(fields: { slug: { eq: "/thankyou/" } }) {
      frontmatter {
        GOOGLE_SALE_CONVERTION_SNIPPET
      }
    }
  }
`;
